$primary-font: brother-1816, sans-serif;
$secondary-font: source-sans-pro, sans-serif;

$primary-color: #3F3E40;
$secondary-color: #f7f7f7;
$orange: #EB6620;
$blue: #2e5c9e;
$green: #4daa57;
$turquoise: #008ca3;
$violet: #e55491;
$yellow: #fba456;
$black: #3F3E40;
$white: #f7f7f7;

$text-color: white;
$secondary-text-color: #222222;

$gray: #e7e7e7;

$header-height: 5rem;
$sidebar-width: 22rem;
$map-zIndex: 450;

$breakpoint-tablet: 900px;
@mixin mobile {
  @media (max-width: $breakpoint-tablet) {
    @content;
  }
}
