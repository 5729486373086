@import '../../scss/variables';

.replay-slider {
  position: absolute;
  z-index: $map-zIndex + 2;
  bottom: 0;
  left: 0;
  right: 0;
  max-width: 50rem;
  padding: 2rem;
  pointer-events: auto;
  margin: auto;
  

  .slider {
    width: 100%;
    height: 3rem;
    margin: 0 auto;

    .track {
      top: 50%;
      transform: translateY(-50%);
      height: 0.5rem;
      box-shadow: 0 3px 15px 0 rgba(0, 0, 0, .2);

      &.track-0 {
        background: rgb(221, 221, 221);
      }

      &.track-1 {
        background: $orange;
      }
    }

    .thumb {
      top: 50%;
      transform: translateY(-50%);
      width: 2rem;
      height: 2rem;
      line-height: 2rem;
      background-color: $orange;
      color: white;
      cursor: pointer;
      border: 2px solid #fff;
      border-radius: 100%;
      box-shadow: 0 0 5px rgba(0, 0, 0, .1);

      > div {
        position: relative;
      }
    }

    .thumb-tooltip {
      $tooltip-width: 11rem;
      $tooltip-margin: (-$tooltip-width / 2) + 1rem;
      top: 50%;
      transform: translateY(-50%);
      position: absolute;
      margin-top: -2.25rem;
      display: flex;
      align-items: center;
      width: $tooltip-width;
      margin-left: $tooltip-margin;
      justify-content: center;

      > div {
        position: relative;
        text-align: center;
        background: rgba(0, 0, 0, 0.8);
        color: #fff;
        padding: 0.33rem 0;
        width: 100%;
        &:after {
          content: '';
          width: 0;
          height: 0;
          border-left: 0.5rem solid transparent;
          border-right: 0.5rem solid transparent;

          border-top: 0.5rem solid rgba(0, 0, 0, 0.8);
          position: absolute;
          bottom: -0.5rem;
          margin: auto;
          left: 0;
          right: 0;
        }
      }
    }

    .mark {
      width: 8px;
      height: 8px;
      border: 2px solid rgb(0, 0, 0);
      background-color: rgb(255, 255, 255);
      cursor: pointer;
      border-radius: 50%;
      vertical-align: middle;
    }
  }
}
