@import '../../../scss/variables';


.poi-icon {
  color: $primary-color;

  &--concrete-plant {
    
  }
}

.leaflet-popup.poi-popup {
  font-size: .9rem;

  .popup__content {
    padding: 1rem;

    h3 {
      font-size: 1rem;
      margin-bottom: 0.5rem;
    }
  }
}