@import '../../scss/variables';

.sidebar {
  flex: 0 0 auto;
  height: calc(var(--vh, 1vh) * 100);
  position: relative;
  z-index: $map-zIndex + 3;
  transform: translateX(0);
  transition: transform 0.25s ease;

  @include mobile {
    position: fixed;
    width: calc(100% - 2.5rem);
  }


  &--closed {
    position: absolute;
    transform: translateX(-100%);
  } 

  &__container {
    flex: 0 0 auto;
    height: 100%;
    width: $sidebar-width;
    position: relative;
    background-color: $secondary-color;
    color: $secondary-text-color;
    box-shadow: 1px 0px 1rem #00000025;

    @include mobile {
      width: 100%;
      max-width: 100%;
    }

    // overflow-y: auto;
  }
}

.sidebar-toggle-button {
  border: none;
  height: 3.5rem;
  width: 2rem;
  position: absolute;
  top: 0rem;
  bottom: 0rem;
  border: none;
  margin: auto;
  right: -2rem;
  background-color: $secondary-color;
  box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.2);

  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &--sidebar-closed {
    >svg {
      transform: rotate(180deg);
    }
  }

  > svg {
    width: 90%;
    height: 90%;
    color: $primary-color;
  }
}
