@import './scss/reset';
@import './scss/variables';

@font-face {
  font-family: 'Biotif';
  src: url('./fonts/biotif-regular-webfont.woff2') format('woff2'),
    url('./fonts/biotif-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Biotif';
  src: url('./fonts/biotif-semibold-webfont.woff2') format('woff2'),
    url('./fonts/biotif-semibold-webfont.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

body,
html {
  width: 100%;
  margin: 0;
  font-size: 16px;
  font-family: 'Biotif', 'Helvetica', 'sans-serif';
  color: $text-color;
  font-weight: 300;
  background-color: #ffffff;
  height: 100%;
}

#root,
.App {
  height: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Biotif', 'Helvetica', 'sans-serif';
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
  letter-spacing: 0.41px;
}

h1 {
  margin-bottom: 2rem;
  font-weight: 300;
  font-size: 2.5rem;
}

h2 {
  font-size: 1.75rem;
}

h3 {
  font-size: 1.5rem;
}

h4 {
  font-size: 1.25rem;
}

strong,
b {
  font-weight: bold;
}

small {
  font-size: 80%;
  font-weight: 400;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.hidden {
  display: none;
}

svg.custom-icon {
  max-width: 100%;
  height: auto;
}

.btn {
  font-family: 'Biotif', 'Helvetica', 'sans-serif';
  position: relative;
  text-decoration: none;
  display: inline-block;
  font-weight: 400;
  text-transform: uppercase;
  color: $text-color;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.5rem 2rem;
  font-size: 0.8rem;
  line-height: 1.5;
  letter-spacing: 1.5px;
  border-radius: 2rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  &:focus {
    outline: none;
  }

  &[disabled] {
    background: #ccc;
    color: #222;
    cursor: not-allowed;
  }

  &--primary {
    background-color: $orange;
    color: #fff;

    &[disabled] {
      background: #ccc;
      color: #222;
      cursor: not-allowed;
    }
  }

  &--secondary {
    background-color: $secondary-color;
    color: $text-color;
  }

  &--white {
    background-color: white;
    color: $primary-color;
  }

  &--circle {
    padding: 1rem;
    border-radius: 100%;
  }

  &--close {
    padding: 0;
    position: absolute;
    display: block;
    height: 2.5rem;
    width: 2.5rem;
    border-radius: 50%;
    color: #2800ff;
    background-color: white;
    border: 0;
    font-weight: 100;

    svg {
      height: 2rem;
      width: 2rem;
      margin: auto;
      vertical-align: middle;
    }
  }

  &--large {
    font-size: 1rem;
    height: 4rem;
    line-height: 4rem;
    min-width: 4rem;
    padding: 0 2rem;
  }

  &--wide {
    font-size: 1rem;
    min-width: 4rem;
    width: 100%;
  }

  &--block {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
    min-width: 0;
    display: block;
  }

  &.text-white {
    color: white;
  }

  &.text-primary {
    color: $primary-color;
  }

  &:hover {
    .btn__tooltip {
      opacity: 1 !important;
      visibility: visible !important;
    }
  }

  &__tooltip {
    transition: opacity 0.4s ease;
    opacity: 0 !important;
    visibility: hidden !important;
    position: absolute;
    bottom: 100%;
    background: #333;
    color: #fff;
    text-align: center;
    border-radius: 0.2rem;
    padding: 0.4rem;
    margin: 0.2rem;
    text-transform: none;
    font-size: 0.8em;
    left: 50%;
    transform: translate(-50%, 0);
  }
}

/* VEHICLE TYPES */
.vehicle-type {
  background-color: $green;
  &--unknown {
    background-color: #7e7b7b;
  }
  &--offline {
    background-color: #7e7b7b;
  }
  &--betoni {
    background-color: $turquoise;
  }
  &--betoni-pumppu {
    background-color: $yellow;
  }
  &--pumppu {
    background-color: $violet;
  }
}
