@import '../../scss/variables';
@import '~leaflet/dist/leaflet.css';

.main-map.leaflet-container {
  // height: calc(100vh - #{$header-height});
  height: calc(var(--vh, 1vh) * 100);
  width: 100%;
  z-index: $map-zIndex;

  .leaflet-popup {
    margin-bottom: 10px;

    .leaflet-popup-content-wrapper {
      box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.1);
      padding: 0;
      border-radius: 0;
      background-color: transparent;

      .leaflet-popup-content {
        padding: 1rem;
        margin: 0;
        background-color: #fff;

        p {
          margin: 0;
        }
      }
    }

    .leaflet-popup-close-button {
      background: $primary-color;
      color: #fff;
      border-radius: 100%;
      height: 30px;
      width: 30px;
      padding: 0;
      line-height: 30px;
      top: -15px;
      right: -15px;
    }
  }

  .leaflet-right {
    .leaflet-control {
      margin-right: 0.5rem;
    }
  }

  .leaflet-top {
    .leaflet-control {
      margin-top: 0.5rem;
    }
  }

  .leaflet-left {
    .leaflet-control {
      margin-left: 0.5rem;
    }
  }

  .leaflet-bottom {
    .leaflet-control {
      margin-bottom: 0.5rem;
    }

    .leaflet-control-scale {
      margin-bottom: 2.5rem;
      z-index: 699;
    }
  }
}

.up {
  // @media only screen and (max-width: 991px) {
  //   height: 40vh !important;
  // }
}

.route-pane {
  z-index: 570;
}

.area-pane {
  z-index: 530;
}

.marker-cluster {
  z-index: 999 !important;
}

.back-btn {
  position: absolute;
  z-index: 900;
  top: 0.7rem;
  left: 0.6rem;
  background-color: $secondary-color;
  color: $primary-color;
  border: 0;
  border-radius: 50%;
  height: 50px;
  width: 50px;
  cursor: pointer;

  svg > path {
    fill: $primary-color;
  }
}

.locate-btn {
  position: fixed;
  background-color: white;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  z-index: 402;
  border: none;
  box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.1);
  bottom: 1rem;
  right: 1rem;
  cursor: pointer;

  &--loading {
    background-color: #ccc;
  }

  path {
    fill: currentColor;
  }

  &.active {
    background-color: $primary-color;

    path {
      fill: white;
    }
  }
}

.here-icon {
  z-index: 400;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: #004285;
}

.watermark {
  color: #002560;
  opacity: 0.4;
  text-decoration: none;
  z-index: 401;
  font-weight: 500;
  margin-bottom: 0.5rem;
  margin-left: 0.5rem;

  &:hover {
    opacity: 0.8;
  }

  .hidden-mobile {
    @media only screen and (max-width: 767px) {
      display: none !important;
    }
  }
}

.marker-cluster {
  background-color: $primary-color;
  border-radius: 50%;
  color: white;
  width: 52px !important;
  height: 52px !important;

  .icon {
    width: 20px;
    height: 20px;
  }

  .icon-wrap {
    display: flex;
    flex-flow: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }

  .count {
    font-family: 'Biotif', 'Helvetica', 'sans-serif';
    font-size: 1.1rem;
    margin-left: 30%;
    position: absolute;
    top: 0.75rem;
  }

  path {
    fill: currentColor;
  }
}

.leaflet-control-attribution {
  display: none;
}


.leaflet-control-attribution,
.leaflet-bottom.leaflet-right {
  z-index: 400;
}

.leaflet-right.leaflet-bottom {
  z-index: 489;

  .leaflet-control {
    margin: 0;

    &.leaflet-control-zoom {
      display: none;

      @media only screen and (min-width: 768px) {
        display: block;
      }

      border: 0;
      border-radius: 0;
      box-shadow: none;

      a {
        box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.1);
        color: $primary-color;
        background: white;
        border: 0;
        padding: 0;
        width: 50px;
        height: 50px;
        line-height: 45px;
        border-radius: 100%;
        font-size: 2.5em;
        font-weight: 100;

        &.leaflet-control-zoom-in {
          margin-bottom: 10px;
        }

        &.leaflet-disabled {
          background: #ccc;
          color: #aaa;
        }
      }
    }
  }
}

.leaflet-control-tilelayers {
  position: relative;
  pointer-events: none;

  .leaflet-control-tilelayers-toggle {
    pointer-events: all;
    display: block;
    color: $primary-color;
    background-color: #fff;
    height: 50px;
    width: 50px;
    border-radius: 10px;
    box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
    > svg {
      height: 66%;
      width: 66%;
    }
  }

  &.leaflet-control-tilelayers-expanded {
    .leaflet-control-tilelayers-toggle {
      color: #fff;
      background-color: $primary-color;
    }
    .leaflet-control-tilelayers-list {
      display: block;
    }
  }

  .leaflet-control-tilelayers-list {
    pointer-events: all;
    position: absolute;
    right: 0;
    top: 100%;
    margin-top: 0.25rem;
    display: none;
    background-color: #fff;
    padding: 1rem;
    color: #000;
    font-size: 1rem;
    box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.1);

    > label {
      display: flex;
      align-items: center;
      margin-bottom: 0.5rem;
      cursor: pointer;
      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
}
