@import '../../scss/variables';

.device-list {
  margin: 0;
  padding: 0;
  list-style: none;
  overflow-y: auto;
  height: calc(100vh - 4.5rem);

  &::-webkit-scrollbar {
    -webkit-appearance: none;
  }

  &::-webkit-scrollbar:vertical {
    width: 0.75rem;
    background-color: transparent;
  }

  &::-webkit-scrollbar:horizontal {
    height: 0.75rem;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 2px solid white;
    background-color: rgba(0, 0, 0, 0.5);
  }

  .title {
    font-size: 1.25rem;
    margin: 1rem;
    margin-left: 0.5rem;
  }

  .list-empty-text {
    text-align: center;
    padding: 1rem;
    color: #aaa;
  }

  .line {
    margin: 0;
    border-top: 1px solid #c7c7c7;
  }

  .device-column {
    display: flex;
    flex-direction: column;
    margin-left: 0.75rem;
  }

  .device-item {
    transition: 0.15s;

    &:hover {
      background-color: #e9e9e9;
    }

    &--info-open {
      .options-container>svg {
        transform: rotate(90deg);
      }
    }
  }

  .device-row {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 1.25rem;

    >.device-type-circle {
      width: 1.75rem;
      height: 1.75rem;
      border-radius: 100%;
    }

    &.device-status--offline {
      opacity: 0.7;

      * {
        color: #7e7b7b !important;
      }

      .device-type-circle {
        // background-color: #7e7b7b;
      }
    }
  }

  .text {
    font-size: 1rem;
  }

  .status-text {
    font-size: 0.75rem;
    margin-left: 0.1rem;

    &.online {
      color: $green;
    }

    &.offline {
      color: #7e7b7b;
    }
  }

  .options-container {
    margin-left: auto;
    width: 2rem;
    height: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;

    >svg {
      transition: transform 0.33s ease;
      width: 100%;
      height: 100%;
    }
  }

  .options {
    width: 1.75rem;
    height: 1.75rem;
    padding: 0.25rem;
    border-radius: 50%;

    path {
      fill: #000000;
    }

    /*
    &:hover {
      transition: 0.12s;
      width: 2rem;
      height: 2rem;
    } */
  }

  .info {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    overflow: hidden;
    transition: max-height 0.33s ease;

    margin-left: 1.25rem;

    &.open {
      max-height: 6rem;
    }

    &.closed {
      max-height: 0rem;
    }

    &__content {
      margin-bottom: 1rem;
    }

    .info-row {
      display: flex;
      flex-direction: row;
      margin-bottom: 0.35rem;
    }

    .value {
      color: $blue;
      margin-left: 0.5rem;
    }
  }
}

.device-filter-menu {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: end;
  border-bottom: 1px solid #aaa;

  .btn--device-filter-menu-open {
    width: auto;
    padding: 1.75rem 1.33rem;
    color: $primary-color;
    background-color: transparent;
    text-align: left;
    border-radius: 0;
    display: flex;
    align-items: center;
    line-height: 1;

    

    &:after {
      content: "";
      width: 0;
      height: 0;
      border-left: .25rem solid transparent;
      border-right: .25rem solid transparent;
      border-top: .25rem solid currentColor;
      margin-left: auto;
      align-self: center;
      pointer-events: none;
      margin-left: 0.75rem;
    }

    >span.value {
      max-width: 10rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    >span.label {
      font-weight: bold;
      margin-right: .75rem;
      position: relative;

      &.filter-active::after {
        content: "";
        background-color: $orange;
        width: 8px;
        height: 8px;
        display: block;
        position: absolute;
        top: -6px;
        right: -6px;
        border-radius: 100%;
      }
    }
  }

  &__list {
    display: none;
    position: absolute;
    background: #fff;
    width: 100%;
    box-shadow: 0 50px 50px 5px rgba(0, 0, 0, 0.15);
    left: 0;
    top: 100%;
    z-index: 2;

    &--open {
      display: block;
    }

    .filter-item {
      padding: 1rem 1.33rem;
      cursor: pointer;
      display: flex;
      align-items: center;

      span.vehicle-type {
        height: 0.75rem;
        width: 0.75rem;
        border-radius: 100%;
        margin-right: 10px;
      }

      &:hover {
        background-color: #f9f9f9;
      }
    }
  }
}