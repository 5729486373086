@import '../../../scss/variables';

.route-menu {
  padding: 6rem 1rem 2rem 1rem;

  .input-row {
    margin-bottom: 2rem;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  .input-label {
    font-size: 1.1rem;
    margin-bottom: 0.25rem;

    &--small {

      font-size: 0.9rem;
    }
  }

  .dropdown-wrap {
    position: relative;
  }

  .dropdown {
    position: relative;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: auto;
    height: 3rem;
    background-color: $gray;
    border-radius: 0.5rem;
    padding: 0 1rem;

    &__selected {
      font-size: 1.3rem;
    }

    &__select {
      position: absolute;
      display: block;
      z-index: 5;
      box-shadow: 0 0 30px 7px rgba(0, 0, 0, 0.15);
      width: 100%;
      border-radius: 0.5rem;
      background-color: $secondary-color;
      top: 0;
    }
    &__content {
      position: relative;
      display: inline-block;
      display: flex;
      flex-direction: column;
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
      max-height: calc(var(--vh, 1vh) * 100 - 8rem);
      overflow-y: auto;
    }
    &__icon {
      pointer-events: none;
      margin-left: auto;
      height: 1.5rem;
      width: 1.5rem;
    }
  }
  .option {
    padding-left: 0.25rem;
    &__text {
      cursor: pointer;
      font-size: 1.15rem;
      margin: 0.5rem;
      margin-left: 0.75rem;
    }
    &:hover {
      background-color: $gray;
    }
  }

  .show-container {
    display: flex;
    justify-content: center;
  }
  .btn-show {
    margin-top: 1rem;
    // border: solid 1px $primary-color;
    &:active {
      margin-top: 1.05rem;
    }
    // &:disabled {
    //   background-color: $secondary-color;
    //   color: $primary-color;
    //   border: solid 1px $primary-color;
    //   color: rgba($primary-color, 0.75);
    //   border: solid 1px rgba($primary-color, 0.75);
    //   &:hover {
    //     background-color: $secondary-color;
    //   }
    // }
  }

  .custom-time-select {
    margin-top: -1rem;
    background: #f1f1f1;
    padding: 1rem;
  }

  .date-input {
    height: 3rem;
    background-color: $gray;
    border-radius: 0.5rem;
    width: 100%;
    max-width: 100%;
    border: none;

    &:focus {
      outline: none;
    }

    &::-webkit-datetime-edit {
      padding: 1em;
      font: $primary-font;
    }
    &::-webkit-calendar-picker-indicator {
      margin: 1rem;
    }
  }
}
