@import '../../../scss/variables';

.blur {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1001;
  width: 100vw;
  height: 100vh;
  background-color: #00000060;
}
