@import '../../scss/variables';

$menu-z-index: 1002;
.menu {
  position: absolute;
  top: 0;
  left: 0;
  width: 22rem;
  height: 100vh;
  z-index: $menu-z-index;
  background-color: $secondary-color;
  color: $secondary-text-color;
  transition: transform 0.33s ease-in;
  transform: translateX(0);

  @include mobile {
    width: 100%;
    max-width: 100%;
  }

  &.closed {
    transform: translateX(-100%);
  }

  &__content {
    margin-top: 5rem;
  }

  &__row {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 1rem;
    cursor: pointer;
    text-decoration: none;
    color: $secondary-text-color;

    &:hover, &.active {
      transition: 0.15s;
      background-color: #e6e6e6;
    }

    .menu-icon {
      width: 2rem;
      height: 2rem;
    }
  }
  .sub-title {
    font-size: 1rem;
    margin: 1.25rem 0rem 0.25rem 1rem;
    color: $secondary-text-color;
  }
  .text {
    font-size: 1.25rem;
    margin-left: 1rem;
    color: $secondary-text-color;
  }
}


.menu-toggle-button {
  height: 50px;
  width: 50px;
  border-radius: 10px;
  position: fixed;
  z-index: $menu-z-index + 1;
  top: .5rem;
  left: .5rem;
  background-color: $secondary-color;
  box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.1);

  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  >svg {
    width: 66%;
    height: 66%;
    color: $primary-color;
  }
} 