@import '../../scss/variables';

.map-container {
  flex-grow: 1;
  position: relative;
}

.main-page {
  display: flex;
  overflow: hidden;
  flex-grow: 1;
  flex-direction: row;
  // max-height: calc(100vh - #{$header-height});
  max-height: calc(var(--vh, 1vh)*100);
}