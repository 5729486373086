@import '../../../scss/variables';

.leaflet-marker-icon.marker {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;

  .circle {
    flex: 1;
    height: 100%;
    width: 100%;
    border-radius: 100%;
    display: block;
    transition: background-color 0.2s, box-shadow 0.2s;
    background-color: #888;

    &.status {

      &--unknown,
      &--offline {

        background-color: #888;

      }
    }

    &.highlight,
    &:hover {
      box-shadow: 0 0 10px 0 $green;
    }

  }

  .arrow {
    display: block;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;

    border-bottom: 5px solid black;
    margin-top: 0px;
  }
}

.marker-popup {
  &__content {
    font-size: 1rem;
    max-width: 33rem;
  }

  .info-row {
    display: flex;
    flex-flow: column;
    margin-bottom: 0.5rem;

    .label {
      flex: 100%;
      font-size: 0.9rem;
      color: #888;
    }

    .value {
      color: $blue;
      font-weight: 500;
    }

  }
}



.leaflet-tooltip.tooltip {
  border: none;
  background-color: transparent;
  // box-shadow: none;
  padding: 0;
  opacity: 1;
  font-size: 0.8rem;
  box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.2);

  &::before {
    display: none;
  }

  .tooltip__content {
    padding: 4px;
    opacity: 1;
    width: 100%;
    height: 100%;
    border-color: #fff;
    background-color: #fff;
    color: #222;

    // &::before {
    //   content: "";
    //   position: absolute;
    //   pointer-events: none;
    //   border: 6px solid transparent;
    //   background: transparent;
    //   left: 50%;
    //   margin-left: -6px;
    //   bottom: 0;
    //   margin-bottom: -11px;
    //   border-top-color: inherit;
    // }
  }
}